import React, { useState, useEffect } from "react";
import { StyledContainer, StyledPageTitle, StyledOrderBlock } from "./style";
import ReactDataTable from "../../../Components/ReactDataTable/ReactDataTable";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteOrdersData,
  getAllFolderCount,
  getOrdersList,
} from "../../../redux/orderSlice";
import { toast } from "react-toastify";
import Modal from "../../../Components/Modal/index";
import { encryptVal } from "../../../utility/utility";
import moment from "moment";
import jwt from "jwt-decode";

const OrderHistory = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const OrderHistoryList = useSelector((state) => state?.order?.ordersList);
  const [show, setShow] = useState(false);
  const [selectedData, setSelectedData] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const handleClose = () => setShow(false);
  const handleShow = (orderId) => {
    setShow(true);
    setSelectedData([Number(orderId.slice(4, 10))]);
  };
  const token = localStorage.getItem("user");
  let userType;
  if (token !== null) {
    const decodedToken = jwt(token);
    userType = decodedToken?.user?.type;
  }

  const deleteOrder = () => {
    let data = {
      orderId: selectedData,
      permanentDeletedFlag: false,
      updatingStatus: "Deleted Orders",
    };
    dispatch(deleteOrdersData(data))
      .then((res) => {
        handleClose();
        if (res.payload) {
          toast.success(`Order has been deleted successfully`, {
            className: "toast-message",
          });
          dispatch(getAllFolderCount());
        } else {
          toast.error(`${res.message}`, {
            className: "toast-message",
          });
        }
      })
      .catch((err) => {
        if (err.status === 0) {
          toast.success(`${err.message}`, {
            className: "toast-message",
          });
        }
      });
  };

  useEffect(() => {
    dispatch(
      getOrdersList({
        orderName: "allorder",
        page: page,
        perPage: limit,
      })
    )
      .unwrap()
      .then((res) => {
        if (res.status === 401) {
          toast.error(`${res?.message}`, {
            className: "toast-message",
          });
          localStorage.removeItem("user");
          navigate("/");
        }
      });

    document.title = "Order History| USA Passport";
  }, [dispatch, navigate, page, limit]);

  const tableData = OrderHistoryList;

  const columns = [
    {
      name: "Order Id",
      width: "7rem",
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: "Name",
      width: "12rem",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Email",
      width: "15rem",
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: "Telephone",
      width: "8rem",
      selector: (row) => row.phone,
      sortable: true,
    },
    {
      name: "Date",
      width: "7rem",
      selector: (row) =>
        row?.date ? moment(new Date(row?.date)).format("MM-DD-YYYY") : null,
      sortable: true,
    },
    {
      name: "Time",
      width: "7rem",
      selector: (row) => row.time,
      sortable: true,
    },
    {
      name: "Assign",
      width: "8rem",
      selector: (row) => row.assignto,
      sortable: true,
    },
    {
      name: "Status",
      width: "9rem",
      selector: (row) => row.status,
      sortable: true,
    },
    {
      name: "Action",
      width: "15rem",
      selector: (row) =>
        tableData?.length ? (
          <span>
            <Link
              to={`/admin/view-order/${encryptVal(
                row?.id?.length > 7 ? row?.id?.slice(4, 10) : row?.id
              )}`}
              style={{
                border: "1px solid #18f",
                borderRadius: "5px",
                padding: " 0 5px",
                minWidth: "80px",
                display: "inline-block",
                textAlign: "center",
                height: "40px",
                lineHeight: "40px",
                color: "#111",
                margin: "5px",
                textDecoration: "none",
              }}
              className="blue-border"
            >
              View
            </Link>{" "}
            {userType === "Manager" || userType === "Admin" ? (
              <Link
                to="#"
                onClick={() => handleShow(row?.id)}
                style={{
                  textDecoration: "none",
                  border: "1px solid #e92a11",
                  borderRadius: "5px",
                  padding: " 0 5px",
                  minWidth: "80px",
                  display: "inline-block",
                  textAlign: "center",
                  height: "40px",
                  lineHeight: "40px",
                  color: " #e92a11",
                  margin: "5px",
                }}
              >
                Delete
              </Link>
            ) : null}
          </span>
        ) : (
          " "
        ),
    },
  ];
  return (
    <StyledContainer>
      <StyledPageTitle>
        <h1>Order History</h1>
      </StyledPageTitle>
      <form>
        <StyledOrderBlock>
          <div className="table-block table-responsive">
            <ReactDataTable
              customColumns={columns}
              customData={tableData}
              setPage={setPage}
              setLimit={setLimit}
              orderName="allorder"
            />
          </div>
        </StyledOrderBlock>
        <Modal
          show={show}
          close={handleClose}
          size={"s"}
          confirmAlert={true}
          noEvent={() => handleClose()}
          yesEvent={() => deleteOrder()}
        ></Modal>
      </form>
    </StyledContainer>
  );
};

export default OrderHistory;
