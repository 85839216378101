import React, { useEffect, useState } from "react";
import {
  StyledContainer,
  StyledPageTitle,
  StyledOrderBlock,
  StyledBottomBlock,
} from "./style";
import ReactDataTable from "../../../Components/ReactDataTable/ReactDataTable";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getOrdersList, getPhonenumberList } from "../../../redux/orderSlice";
import { toast } from "react-toastify";
import { CSVLink } from "react-csv";
import moment from "moment";

const ContactCustomer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const ContactCustomersList = useSelector((state) => state?.order?.ordersList);
  const PhoneNumberList = useSelector((state) => state?.order?.phoneNumberList);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  useEffect(() => {
    dispatch(
      getOrdersList({
        orderName: "contactcustomerorder",
        page: page,
        perPage: limit,
      })
    )
      .unwrap()
      .then((res) => {
        if (res.status === 401) {
          toast.error(`${res?.message}`, {
            className: "toast-message",
          });
          localStorage.removeItem("user");
          navigate("/");
        }
      });
    dispatch(getPhonenumberList());
    document.title = "Contact Customer | USA Passport";
  }, [dispatch, navigate, page, limit]);

  const csvData = PhoneNumberList?.length > 0 ? PhoneNumberList : [];

  const tableData = ContactCustomersList;

  const date = moment(new Date()).format("DD-MM-yyyy");
  return (
    <StyledContainer>
      <StyledPageTitle>
        <h1>Contact Customer</h1>
      </StyledPageTitle>
      <form>
        <StyledOrderBlock>
          <div className="table-block table-responsive">
            <ReactDataTable
              data={tableData}
              setPage={setPage}
              setLimit={setLimit}
              orderName="contactcustomerorder"
            />
          </div>
        </StyledOrderBlock>
        <StyledBottomBlock>
          <div className="row">
            <div className="col-sm-6">
              <CSVLink
                data={csvData}
                className="btn blue-btn"
                filename={`${date}-phone.csv`}
                target="_blank"
              >
                Download in CSV{" "}
              </CSVLink>
            </div>
          </div>
        </StyledBottomBlock>
      </form>
    </StyledContainer>
  );
};

export default ContactCustomer;
