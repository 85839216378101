import React, { useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { StyledOrderBlock, StyledBottomBlock } from "./style";
import { FaDownload } from "react-icons/fa";
import rightArrow from "../../assests/images/right-arrow.png";
import { useDispatch, useSelector } from "react-redux";
import {
  getOrderDetailsByOrderId,
  resendMail,
  uploadVisaDocument,
  removeCustomerAllData
} from "../../redux/orderSlice";
import moment from "moment";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as yup from "yup";
import AddComment from "./AddComment/AddComment";
import MyModal from "../Modal/Modal";
import DefenceContent from "./DefenceContent/DefenceContent";
import TransactionModal from "./TransactionModal/TransactionModal";
import EmailContent from "./EmailContent/EmailContent";

const ViewOrders = () => {
  const dispatch = useDispatch();
  const { orderId } = useParams();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [showDefence, setShowDefence] = useState(false);
  const [showTransaction, SetShowTransaction] = useState(false);
  const [showDeleteRecord ,setShowDeleteRecord] = useState(false);
  const [showDefencePack, setShowDefencePack] = useState(false);
  const [mailServiceProvider, setMailServiceProvider] = useState();

  const OrderDetails = useSelector((state) => state?.order?.orderDetails);
  const isLoading = useSelector((state) => state?.order?.loading);

  const [selectedData, setSelectedData] = useState({
    showData: false,
    dataId: 0,
  });

  const [emailId, setEmailId] = useState(OrderDetails?.email);
  const [orderid, setEmailIdOrderid] = useState(OrderDetails?.id);
  let userDetail;
  let bookDataChanged;
  let cardDataChanged;

  const uploadSchema = yup.object({
    email: yup.string().required("Please enter your email"),
    fileUpload: yup.mixed().required("File is required"),
  });

  useEffect(() => {
    if (orderId) {
      dispatch(getOrderDetailsByOrderId(orderId));
    }

    document.title = "Orders Details | USA Passport";
  }, [dispatch, orderId]);

  useEffect(() => {
    if (
      (OrderDetails &&
        typeof OrderDetails !== "undefined" &&
        OrderDetails?.email &&
        typeof emailId === "undefined") ||
      orderId !== orderid
    ) {
      setEmailId(OrderDetails?.email);
    }
  }, [OrderDetails, orderid]);

  if (
    OrderDetails &&
    typeof OrderDetails !== "undefined" &&
    typeof OrderDetails?.user_device_detail !== "undefined"
  ) {
    try {
      userDetail = JSON.parse(OrderDetails?.user_device_detail);
    } catch (e) {
      userDetail = {};
    }
  }

  if (
    OrderDetails &&
    typeof OrderDetails !== "undefined" &&
    typeof OrderDetails?.BookDataChanged !== "undefined" &&
    OrderDetails?.BookDataChanged !== ""
  ) {
    bookDataChanged = JSON.parse(OrderDetails?.BookDataChanged);
  }

  if (
    OrderDetails &&
    typeof OrderDetails !== "undefined" &&
    typeof OrderDetails?.CardDataChanged !== "undefined" &&
    OrderDetails?.CardDataChanged !== ""
  ) {
    cardDataChanged = JSON.parse(OrderDetails?.CardDataChanged);
  }

  let defaultInitialValues = {
    orderId: `${OrderDetails?.pre_id}${OrderDetails?.id}`,
    email: "",
    fileUpload: "",
  };

  if (orderId !== null && orderId !== undefined && OrderDetails !== null) {
    defaultInitialValues = {
      orderId: `${OrderDetails?.pre_id}${OrderDetails?.id}`,
      email: OrderDetails?.email,
      fileUpload: "",
    };
  }

  let [initialValues, setInitialValues] = useState(defaultInitialValues);

  const folderName =
    OrderDetails?.order_change_status === "Awiating Response"
      ? "Awiating Order"
      : OrderDetails?.order_change_status === "Deleted Orders"
      ? "Deleted Order"
      : OrderDetails?.order_change_status === "Complete Refunds"
      ? "Refund Order"
      : OrderDetails?.order_change_status === "Contact Customer"
      ? "Contact Customer Order"
      : OrderDetails?.doc_uploaded === 0 &&
        (OrderDetails?.order_change_status === null ||
          OrderDetails?.order_change_status === "")
      ? "New Order"
      : OrderDetails?.doc_uploaded === 1 &&
        OrderDetails?.uploadDoc[0]?.is_downloaded === 0 &&
        (OrderDetails?.order_change_status === null ||
          OrderDetails?.order_change_status === "")
      ? "Pending Order"
      : OrderDetails?.doc_uploaded === 1 &&
        OrderDetails?.uploadDoc[0]?.is_downloaded === 1 &&
        (OrderDetails?.order_change_status === null ||
          OrderDetails?.order_change_status === "")
      ? "Completed Order"
      : null;

  const {
    values,
    errors,
    handleBlur,
    handleSubmit,
    handleChange,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues: orderId !== null ? defaultInitialValues : initialValues,
    enableReinitialize: true,
    validationSchema: uploadSchema,
    onSubmit: (values) => {
      const data = new FormData();
      data.append("orderId", values.orderId.slice(4, 10));
      data.append("email", values.email);
      data.append("uploadVisaDoc", values.fileUpload);
      data.append("folderName", folderName);
      data.append("mailServiceProvider", mailServiceProvider);
      dispatch(uploadVisaDocument(data))
        .unwrap()
        .then((res) => {
          resetForm();
          if (res.status === 1) {
            toast.success(`${res.message}`, {
              className: "toast-message",
            });
            dispatch(getOrderDetailsByOrderId(orderId));
          }
        });
    },
  });

  const handleResendMail = (e, serviceProvider) => {
    e.preventDefault();
    setMailServiceProvider(serviceProvider);
    const data = {
      orderId: OrderDetails?.id,
      email: emailId,
      folderName: folderName,
      mailServiceProvider: serviceProvider, 
    };
    dispatch(resendMail(data))
      .unwrap()
      .then((res) => {
        if (res.status === 1) {
          toast.success(`${res.message}`, {
            className: "toast-message",
          });
          dispatch(getOrderDetailsByOrderId(orderId));
        }
      });
  };

  const handleClose = () => setShowDeleteRecord(false);

  const handleRemoveAllRecord = (e) => {
    // console.log("event",e);
    // e.preventDefault();
    const data = {
      orderId: OrderDetails?.id,
    };
    dispatch(removeCustomerAllData(data)).unwrap().then((res) => {
      if (res.status === 1) {
        toast.success(`${res.message}`, {
          className: "toast-message",
        });
        navigate('/admin/document-log');
      }
    });
  };

  const date = new Date();
  const currentYear = date.getFullYear();
  let year;
  let diff;

  if (
    OrderDetails &&
    typeof OrderDetails !== "undefined" &&
    OrderDetails?.BookIssueDate !== null &&
    typeof OrderDetails?.BookIssueDate !== "undefined"
  ) {
    year = moment(OrderDetails?.BookIssueDate).format("YYYY");
    diff = currentYear - year;
  }

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          {OrderDetails?.mailHistory?.length !== 0 && (
            <StyledOrderBlock>
              <h2>Email History</h2>
              <div className="table-block table-responsive">
                <table>
                  <thead>
                    <tr>
                      <th style={{ width: "5%" }}>#</th>
                      <th style={{ width: "10%" }}>Order ID</th>
                      <th style={{ width: "30%" }}>Download</th>
                      <th style={{ width: "25%" }}>Date & Time</th>
                      <th style={{ width: "30%" }}>Mail Provider</th>
                    </tr>
                  </thead>
                  <tbody>
                    {OrderDetails?.mailHistory?.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td className="font-normal">
                            {OrderDetails?.pre_id}
                            {item?.order_id}
                          </td>
                          {OrderDetails?.uploadDoc?.length ? (
                            <td>
                              <a
                                href={
                                  OrderDetails?.length !== 0
                                    ? `${process.env.REACT_APP_BASE_URL}/${
                                        process.env.REACT_APP_PUBLIC_UPLOAD_PATH
                                      }docs/${
                                        item?.uploadDoc == null
                                          ? OrderDetails?.uploadDoc[0]?.file1
                                          : item?.uploadDoc.file1
                                      }`
                                      ? `${process.env.REACT_APP_BASE_URL}/${
                                          process.env
                                            .REACT_APP_PUBLIC_UPLOAD_PATH
                                        }docs/${
                                          item?.uploadDoc == null
                                            ? OrderDetails?.uploadDoc[0]?.file1
                                            : item?.uploadDoc.file1
                                        }`
                                      : `https://usa-passport.com/uploads/${
                                          item?.uploadDoc == null
                                            ? OrderDetails?.uploadDoc[0]?.file1
                                            : item?.uploadDoc.file1
                                        }`
                                    : null
                                }
                                className="blue-btn"
                                download
                                rel="noreferrer"
                              >
                                Download File{" "}
                                <span>
                                  <FaDownload className="download-icons" />
                                </span>
                              </a>
                            </td>
                          ) : (
                            ""
                            )}
                          <td>
                            {moment(item?.create_ts).format(
                              "MM/DD/YYYY h:mm:ss"
                              )}
                          </td>
                          <td>{item?.uploadDoc?.mail_service_provider}</td>

                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                <form className="form-inline">
                  <div className="form-group">
                    <label>
                      <input
                        type="email"
                        name="email"
                        id="email"
                        className="form-control input-class"
                        value={emailId}
                        onChange={(e) => setEmailId(e.target.value)}
                      />
                    </label>
                  </div>
                  <div className="form-group">
                    <button
                      type="button"
                      className="blue-btn"
                      id="download_btn_aws"
                      disabled={isLoading}
                      onClick={(e) => {
                        handleResendMail(e, "AWS");
                      }}
                    >
                      Sent Mail(AWS){" "}
                      <span>
                        <img src={rightArrow} alt="rightArrow" />
                      </span>
                    </button>
                    <button
                      type="button"
                      className="blue-btn"
                      id="download_btn_sendgrid"
                      disabled={isLoading}
                      onClick={(e) => {
                        handleResendMail(e, "Sendgrid");
                      }}
                    >
                      Sent Mail(Sendgrid){" "}
                      <span>
                        <img src={rightArrow} alt="rightArrow" />
                      </span>
                    </button>
                  </div>
                </form>
              </div>
            </StyledOrderBlock>
          )}
        </div>
        <div className="col-md-12">
          <StyledOrderBlock>
            <h2>Upload Document</h2>
            <div className="table-block table-responsive fw-mobileview">
              <form onSubmit={handleSubmit}>
                <table>
                  <tbody>
                    <tr>
                      <td style={{ width: "15%" }}>Order ID</td>
                      <td style={{ width: "35%" }} className="font-normal">
                        <input
                          type="text"
                          name="orderId"
                          className="form-control"
                          placeholder="Order ID"
                          disabled
                          value={values?.orderId}
                        />
                      </td>
                      <td style={{ width: "15%" }}>Email ID</td>
                      <td style={{ width: "35%" }}>
                        <input
                          type="email"
                          name="email"
                          className="form-control"
                          value={values?.email}
                          onChange={handleChange}
                          placeholder="Email"
                        />
                        <p>{errors.email}</p>
                      </td>
                    </tr>
                    <tr>
                      <td>PDF</td>
                      <td>
                        <label className="file-upload">
                          <input
                            type="file"
                            className=""
                            id="fileUpload"
                            name="fileUpload"
                            accept=".pdf"
                            onChange={(e) =>
                              setFieldValue(
                                "fileUpload",
                                e.currentTarget.files[0]
                              )
                            }
                            onBlur={handleBlur}
                          />
                          <span>Choose File</span>
                        </label>
                        <span className="slcted-file"></span>
                        <p>{errors.fileUpload}</p>
                      </td>
                      {OrderDetails?.uploadDoc?.length ? (
                        <td colSpan="2">
                          <a
                            href={
                              OrderDetails?.length !== 0
                                ? `${process.env.REACT_APP_BASE_URL}/${process.env.REACT_APP_PUBLIC_UPLOAD_PATH}docs/${OrderDetails?.uploadDoc[0]?.file1}`
                                  ? `${process.env.REACT_APP_BASE_URL}/${process.env.REACT_APP_PUBLIC_UPLOAD_PATH}docs/${OrderDetails?.uploadDoc[0]?.file1}`
                                  : `https://usa-passport.com/uploads/${OrderDetails?.uploadDoc[0]?.file1}`
                                : null
                            }
                            className="blue-btn"
                            download
                            rel="noreferrer"
                          >
                            Download File{" "}
                            <span>
                              <FaDownload className="download-icons" />
                            </span>
                          </a>
                        </td>
                      ) : (
                        ""
                      )}
                    </tr>
                  </tbody>
                </table>
                <br />
                <button
                  type="submit"
                  className="blue-btn"
                  id="submit_btn"
                  onClick={() => setMailServiceProvider('AWS') }
                  disabled={isLoading}
                >
                  Submit (AWS) {" "}
                  <span>
                    <img src={rightArrow} alt="rightArrow" />
                  </span>
                </button>
                <button
                  type="submit"
                  className="blue-btn"
                  id="submit_btn_sendgrid"
                  onClick={() => setMailServiceProvider('Sendgrid') }
                  disabled={isLoading}
                >
                  Submit (Sendgrid){" "}
                  <span>
                    <img src={rightArrow} alt="rightArrow" />
                  </span>
                </button>
                <br />
                <button
                  type="submit"
                  className="blue-btn"
                  id="remove_btn"
                  disabled={isLoading}
                  onClick={() => setShowDeleteRecord(true)}
                  // onClick={handleRemoveAllRecord}
                >
                  Delete Record{" "}
                  <span>
                    <img src={rightArrow} alt="rightArrow" />
                  </span>
                </button>
              </form>
            </div>
          </StyledOrderBlock>
        </div>

        <div className="col-md-12">
          <StyledOrderBlock>
            <h2>Order Details</h2>
            <div className="table-block table-responsive">
              <table>
                <tbody>
                  <tr>
                    <td>Order ID:</td>
                    <td>
                      {OrderDetails?.pre_id}
                      {OrderDetails?.id}
                    </td>
                  </tr>

                  <tr>
                    <td>
                      I have read the Privacy Policy and accept the Terms of
                      Business.
                    </td>
                    <td>Yes</td>
                  </tr>

                  <tr>
                    <td>Application type.</td>
                    <td>
                      {OrderDetails?.application_type === "1"
                        ? "Apply online"
                        : ""}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </StyledOrderBlock>

          {OrderDetails?.applicant_details === "1" ? (
            <StyledOrderBlock>
              <h2>About the Applicant</h2>
              <div className="row">
                <div className="col-md-6 pdr-0">
                  <div className="table-block table-responsive">
                    <table>
                      <tbody>
                        <tr>
                          <td>First Name</td>
                          <td>{OrderDetails?.firstName}</td>
                        </tr>
                        <tr>
                          <td>Last Name</td>
                          <td>{OrderDetails?.lastName}</td>
                        </tr>
                        <tr>
                          <td>Date Of Birth</td>
                          <td>
                            {" "}
                            {OrderDetails?.dob
                              ? moment(OrderDetails?.dob).format("MM/DD/YYYY")
                              : ""}
                          </td>
                        </tr>
                        <tr>
                          <td>Country Of Birth</td>
                          <td>{OrderDetails?.CountryList}</td>
                        </tr>
                        <tr>
                          <td>Social Security Number</td>
                          <td>{OrderDetails?.ssn}</td>
                        </tr>

                        <tr>
                          <td>Gender</td>
                          <td>{OrderDetails?.sexList}</td>
                        </tr>
                        <tr>
                          <td>Hair Color</td>
                          <td>{OrderDetails?.hairList}</td>
                        </tr>
                        <tr>
                          <td>Occupation</td>
                          <td>{OrderDetails?.occupation}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="col-md-6 pdl-0">
                  <div className="table-block table-responsive">
                    <table>
                      <tbody>
                        <tr>
                          <td>Middle Name</td>
                          <td>{OrderDetails?.middleName}</td>
                        </tr>
                        <tr>
                          <td>Suffix</td>
                          <td>{OrderDetails?.suffixName}</td>
                        </tr>
                        <tr>
                          <td>City Of Birth</td>
                          <td>{OrderDetails?.City}</td>
                        </tr>
                        <tr>
                          <td>State or Territory Of Birth</td>
                          <td>{OrderDetails?.StateList}</td>
                        </tr>
                        <tr>
                          <td colSpan="2">&nbsp;</td>
                        </tr>
                        <tr>
                          <td>Height</td>
                          <td>{`${OrderDetails?.heightFootList}:${OrderDetails?.heightInchList}`}</td>
                        </tr>
                        <tr>
                          <td>Eye Color</td>
                          <td>{OrderDetails?.eyeList}</td>
                        </tr>
                        <tr>
                          <td>Employer or School</td>
                          <td>{OrderDetails?.employer}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </StyledOrderBlock>
          ) : null}

          {OrderDetails?.mail_address === "1" ? (
            <StyledOrderBlock>
              <h2>Contact Information</h2>
              <div className="table-block table-responsive">
                <table>
                  <tbody>
                    <tr>
                      <td>Street Address RFD#, P.O. Box, or URB: </td>
                      <td>{OrderDetails?.mailStreet}</td>
                    </tr>

                    <tr>
                      <td>Street Address 2</td>
                      <td>{OrderDetails?.mailStreet2}</td>
                    </tr>

                    <tr>
                      <td>City</td>
                      <td>{OrderDetails?.mailCity}</td>
                    </tr>

                    <tr>
                      <td>Country</td>
                      <td>{OrderDetails?.mailCountryList}</td>
                    </tr>
                    <tr>
                      <td>State</td>
                      <td>{OrderDetails?.mailStateList}</td>
                    </tr>
                    <tr>
                      <td>Zip Code</td>
                      <td>{OrderDetails?.mailZip}</td>
                    </tr>
                    <tr>
                      <td>
                        In Care Of (e.g. In Care Of - Jane Doe). <br />
                        If applicant is a child, write 'In Care Of' of the
                        parent. Example: In Care Of- Jane Doe, mother.
                      </td>
                      <td>{OrderDetails?.mailCareOf}</td>
                    </tr>
                    <tr>
                      <td>Is This Your Permanent Address </td>
                      <td>{OrderDetails?.permanentAddressList}</td>
                    </tr>
                    {OrderDetails?.permanentAddressList === "No" && (
                      <>
                        <tr>
                          <td colSpan="2" className="order-detail-header">
                            <b style={{ fontSize: "19px" }}>
                              <u>Permanent Address</u>
                            </b>
                          </td>
                        </tr>
                        <tr>
                          <td>Street Address/RFD</td>
                          <td>{OrderDetails?.permanentStreet}</td>
                        </tr>
                        <tr>
                          <td>Street Address 2</td>
                          <td>{OrderDetails?.Permanent_Street}</td>
                        </tr>
                        <tr>
                          <td>Apt./Suite</td>
                          <td>{OrderDetails?.permanentApartment}</td>
                        </tr>
                        <tr>
                          <td>City</td>
                          <td>{OrderDetails?.permanentCity}</td>
                        </tr>
                        <tr>
                          <td>Country</td>
                          <td>{OrderDetails?.permanentCountryList}</td>
                        </tr>
                        <tr>
                          <td>State</td>
                          <td>{OrderDetails?.permanentStateList}</td>
                        </tr>
                        <tr>
                          <td>Zip</td>
                          <td>{OrderDetails?.permanentZip}</td>
                        </tr>
                      </>
                    )}

                    <tr>
                      <td>Your Email Address </td>
                      <td>{OrderDetails?.email}</td>
                    </tr>

                    {}
                    <tr>
                      <td> Phone Number (no dashes)</td>
                      <td>
                        {OrderDetails?.phoneNumber1 !== ""
                          ? `${OrderDetails?.phoneNumber1} (H)`
                          : null}
                        <br />
                        {OrderDetails?.phoneNumber2 !== ""
                          ? `${OrderDetails?.phoneNumber2} (W)`
                          : null}
                        <br />
                        {OrderDetails?.phoneNumber3 !== ""
                          ? `${OrderDetails?.phoneNumber3} (C)`
                          : null}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </StyledOrderBlock>
          ) : null}

          {OrderDetails?.travel_plans === "1" && (
            <StyledOrderBlock>
              <h2>Travel Plans</h2>

              <div className="table-block table-responsive">
                <table>
                  <tbody>
                    <tr>
                      <td> Date Of Your Trip (MM/DD/YYYY) </td>
                      <td>
                        {OrderDetails?.TripDate
                          ? moment(OrderDetails?.TripDate).format("MM/DD/YYYY")
                          : null}
                      </td>
                    </tr>
                    <tr>
                      <td> Duration Of Your Return (MM/DD/YYYY) </td>
                      <td>
                        {OrderDetails?.TripLength
                          ? moment(OrderDetails?.TripLength).format(
                              "MM/DD/YYYY"
                            )
                          : null}
                      </td>
                    </tr>

                    <tr>
                      <td> Countries To Be Visited </td>
                      <td>{OrderDetails?.Countries_visit}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </StyledOrderBlock>
          )}

          {OrderDetails?.emergency_contacts === "1" && (
            <StyledOrderBlock>
              <h2>Emergency Contact</h2>
              <div className="table-block table-responsive">
                <table>
                  <tbody>
                    <tr>
                      <td> First & Last Name </td>
                      <td>{OrderDetails?.emergency_ContactName}</td>
                    </tr>

                    <tr>
                      <td> Street Address / RFD# Or P.O. Box </td>
                      <td>{OrderDetails?.emergency_ContactsAddress}</td>
                    </tr>

                    <tr>
                      <td>Apt/Suite# </td>
                      <td>{OrderDetails?.emergency_ContactApartment}</td>
                    </tr>

                    <tr>
                      <td>City</td>
                      <td>{OrderDetails?.emergency_ContactsCity}</td>
                    </tr>

                    <tr>
                      <td>State</td>
                      <td>{OrderDetails?.emergency_ContactsState}</td>
                    </tr>

                    <tr>
                      <td>Zip Code</td>
                      <td>{OrderDetails?.emergency_ZipCode}</td>
                    </tr>

                    <tr>
                      <td>Telephone Number </td>
                      <td>{OrderDetails?.emergency_Phone}</td>
                    </tr>

                    <tr>
                      <td>Relationship </td>
                      <td>{OrderDetails?.emergency_Relationship}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </StyledOrderBlock>
          )}

          {OrderDetails?.passport_type !== "" && (
            <StyledOrderBlock>
              <h2>Most Recent Passport</h2>
              <div className="table-block table-responsive">
                <table>
                  <tbody>
                    <tr>
                      <td>Recent Passport </td>
                      <td>
                        {OrderDetails?.passport_type === "1"
                          ? "Passport Book"
                          : OrderDetails?.passport_type === "2"
                          ? "Passport Card"
                          : OrderDetails?.passport_type === "3"
                          ? "Both"
                          : OrderDetails?.passport_type === "4"
                          ? "None"
                          : null}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </StyledOrderBlock>
          )}

          {OrderDetails?.mostrecent_passportbook === "1" && (
            <StyledOrderBlock>
              <h2>Passport Book</h2>
              <div className="table-block table-responsive">
                <table>
                  <tbody>
                    <tr>
                      <td>Do you still have the book in your possession </td>
                      <td>
                        {" "}
                        {OrderDetails?.passport_Book === "1"
                          ? "Yes"
                          : OrderDetails?.passport_Book === "2"
                          ? "Yes, but it was Damaged or Mutilated"
                          : OrderDetails?.passport_Book === "3"
                          ? "No, it was Lost"
                          : OrderDetails?.passport_Book === "4"
                          ? "No, it has been Stolen"
                          : null}
                      </td>
                    </tr>
                    {(OrderDetails?.passport_Book === "3" ||
                      OrderDetails?.passport_Book === "4") && (
                      <tr>
                        <td>Have you reported your lost or stolen book</td>
                        <td>{OrderDetails?.ReportLost_book?.toUpperCase()}</td>
                      </tr>
                    )}
                    <tr>
                      <td>
                        The date your most recent passport book was issued{" "}
                      </td>
                      <td>
                        {" "}
                        {OrderDetails?.BookIssueDate
                          ? moment(OrderDetails?.BookIssueDate).format(
                              "MM/DD/YYYY"
                            )
                          : ""}
                      </td>
                    </tr>
                    {(OrderDetails?.passport_Book === "3" ||
                      OrderDetails?.passport_Book === "4") &&
                      (OrderDetails?.ReportLost_book === "No" ||
                        OrderDetails?.ReportLost_book === "no") &&
                      (OrderDetails?.BookIssueDate === "" ||
                        !OrderDetails?.BookIssueDate) && (
                        <tr>
                          <td>
                            Was your lost or stolen passport book issued more
                            than 15 years ago
                          </td>
                          <td>
                            {OrderDetails?.BookExpiredGroup === "1"
                              ? "Yes"
                              : OrderDetails?.BookExpiredGroup === "2"
                              ? "No"
                              : OrderDetails?.BookExpiredGroup === "3"
                              ? "Unknown"
                              : null}
                          </td>
                        </tr>
                      )}

                    <tr>
                      <td>First and Middle Name </td>
                      <td>{OrderDetails?.firstNameOnBook}</td>
                    </tr>

                    <tr>
                      <td>Last Name </td>
                      <td> {OrderDetails?.lastNameOnBook}</td>
                    </tr>

                    <tr>
                      <td>Book number </td>
                      <td>{OrderDetails?.BookNumber}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </StyledOrderBlock>
          )}
          {OrderDetails?.mostrecent_passportcard === "1" && (
            <StyledOrderBlock>
              <h2>Passport Card</h2>
              <div className="table-block table-responsive">
                <table>
                  <tbody>
                    {OrderDetails?.mostrecent_passportcard === "1" && (
                      <>
                        <tr>
                          <td>
                            Do you still have the card in your possession{" "}
                          </td>
                          <td>
                            {OrderDetails?.passport_Card === "1"
                              ? "Yes"
                              : OrderDetails?.passport_Card === "2"
                              ? "Yes, but it was Damaged or Mutilated"
                              : OrderDetails?.passport_Card === "3"
                              ? "No, it was Lost"
                              : OrderDetails?.passport_Card === "4"
                              ? "No, it has been Stolen"
                              : null}
                          </td>
                        </tr>

                        {(OrderDetails?.passport_Card === "3" ||
                          OrderDetails?.passport_Card === "4") && (
                          <tr>
                            <td>Have you reported your lost or stolen card</td>
                            <td>
                              {OrderDetails?.ReportLost_card?.toUpperCase()}
                            </td>
                          </tr>
                        )}

                        <tr>
                          <td>
                            The date your most recent passport card was issued
                          </td>
                          <td>
                            {OrderDetails?.CardIssueDate
                              ? moment(OrderDetails?.CardIssueDate).format(
                                  "MM/DD/YYYY"
                                )
                              : ""}
                          </td>
                        </tr>

                        <tr>
                          <td>First and Middle Name</td>
                          <td>{OrderDetails?.firstNameOnCard}</td>
                        </tr>

                        <tr>
                          <td>Last Name</td>
                          <td>{OrderDetails?.lastNameOnCard}</td>
                        </tr>

                        <tr>
                          <td>Card number</td>
                          <td>{OrderDetails?.CardNumber}</td>
                        </tr>
                      </>
                    )}
                  </tbody>
                </table>
              </div>
            </StyledOrderBlock>
          )}

          {OrderDetails?.mostrecent_bookcard === "1" && (
            <StyledOrderBlock>
              <h2>Passport Book And Card</h2>
              <div className="table-block table-responsive">
                <table>
                  <tbody>
                    <tr>
                      <td colSpan="2" className="order-detail-header">
                        <b style={{ fontSize: "19px" }}>
                          <u>Passport Book</u>
                        </b>
                      </td>
                    </tr>
                    <tr>
                      <td>Do you still have the book in your possession</td>
                      <td>
                        {OrderDetails?.passport_Book === "1"
                          ? "Yes"
                          : OrderDetails?.passport_Book === "2"
                          ? "Yes, but it was Damaged or Mutilated"
                          : OrderDetails?.passport_Book === "3"
                          ? "No, it was Lost"
                          : OrderDetails?.passport_Book === "4"
                          ? "No, it has been Stolen"
                          : null}
                      </td>
                    </tr>

                    {(OrderDetails?.passport_Book === "3" ||
                      OrderDetails?.passport_Book === "4") && (
                      <tr>
                        <td>Have you reported your lost or stolen book</td>
                        <td>{OrderDetails?.ReportLost_book?.toUpperCase()}</td>
                      </tr>
                    )}
                    <tr>
                      <td>
                        The date your most recent passport book was issued
                      </td>
                      <td>
                        {OrderDetails?.BookIssueDate
                          ? moment(OrderDetails?.BookIssueDate).format(
                              "MM/DD/YYYY"
                            )
                          : ""}
                      </td>
                    </tr>
                    {OrderDetails?.passport_Book === "3" ||
                      (OrderDetails?.passport_Book === "4" &&
                        OrderDetails?.ReportLost_book === "no" &&
                        OrderDetails?.BookIssueDate === "" && (
                          <tr>
                            <td>
                              Was your lost or stolen passport book issued more
                              than 15 years ago
                            </td>
                            <td>
                              {OrderDetails?.BookExpiredGroup === "1"
                                ? "Yes"
                                : OrderDetails?.BookExpiredGroup === "2"
                                ? "No"
                                : OrderDetails?.BookExpiredGroup === "3"
                                ? "Unknown"
                                : null}
                            </td>
                          </tr>
                        ))}
                    <tr>
                      <td>First and Middle Name</td>
                      <td>{OrderDetails?.firstNameOnBook}</td>
                    </tr>
                    <tr>
                      <td>Last Name</td>
                      <td>{OrderDetails?.lastNameOnBook}</td>
                    </tr>
                    <tr>
                      <td>Book number</td>
                      <td>{OrderDetails?.BookNumber}</td>
                    </tr>
                    <tr>
                      <td colSpan="2" className="order-detail-header">
                        <b style={{ fontSize: "19px" }}>
                          <u>Passport Card</u>
                        </b>
                      </td>
                    </tr>
                    <tr>
                      <td>Do you still have the card in your possession</td>
                      <td>
                        {OrderDetails?.passport_Card === "1"
                          ? "Yes"
                          : OrderDetails?.passport_Card === "2"
                          ? "Yes, but it was Damaged or Mutilated"
                          : OrderDetails?.passport_Card === "3"
                          ? "No, it was Lost"
                          : OrderDetails?.passport_Card === "4"
                          ? "No, it has been Stolen"
                          : null}
                      </td>
                    </tr>

                    {(OrderDetails?.passport_Card == "3" ||
                      OrderDetails?.passport_Card == "4") && (
                      <tr>
                        <td>Have you reported your lost or stolen card</td>
                        <td>{OrderDetails?.ReportLost_card?.toUpperCase()}</td>
                      </tr>
                    )}
                    <tr>
                      <td>
                        The date your most recent passport card was issued
                      </td>
                      <td>
                        {OrderDetails?.CardIssueDate
                          ? moment(OrderDetails?.CardIssueDate).format(
                              "MM/DD/YYYY"
                            )
                          : ""}
                      </td>
                    </tr>
                    <tr>
                      <td>First and Middle Name</td>
                      <td>{OrderDetails?.firstNameOnCard}</td>
                    </tr>
                    <tr>
                      <td>Last Name</td>
                      <td>{OrderDetails?.lastNameOnCard}</td>
                    </tr>
                    <tr>
                      <td>Card number</td>
                      <td>{OrderDetails?.CardNumber}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </StyledOrderBlock>
          )}

          {OrderDetails?.lost_stolen_card === "1" ? (
            <StyledOrderBlock>
              <h2>Lost Stolen Card</h2>
              <div className="table-block table-responsive">
                <table>
                  <tbody>
                    <tr>
                      <td colSpan="2" className="order-detail-header">
                        <b style={{ fontSize: "19px" }}>
                          <u>Lost Or Stolen Passport Information</u>
                        </b>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Are you reporting your own lost or stolen passport
                      </td>
                      <td>
                        {OrderDetails?.cardlostStolenStep === "1"
                          ? "Yes"
                          : OrderDetails?.cardlostStolenStep === "2"
                          ? "No"
                          : null}
                      </td>
                    </tr>

                    <tr>
                      <td>
                        Did you file a police report? (If yes and the report is
                        available, please submit a copy).
                      </td>
                      <td>
                        {OrderDetails?.policeReport1 === "1" ? "Yes" : "No"}
                      </td>
                    </tr>

                    {OrderDetails?.lostStolenStep === "2" && (
                      <>
                        <tr>
                          <td>Reporter First Name</td>
                          <td>{OrderDetails?.cardreporterFirstName}</td>
                        </tr>

                        <tr>
                          <td>Reporter Middle Name</td>
                          <td>{OrderDetails?.cardreporterMiddleName}</td>
                        </tr>

                        <tr>
                          <td>Reporter Last Name</td>
                          <td>{OrderDetails?.cardreporterLastName}</td>
                        </tr>

                        <tr>
                          <td>
                            What is the reporter's relationship to the owner of
                            the passport
                          </td>
                          <td>{OrderDetails?.cardreporterRelationship}</td>
                        </tr>
                      </>
                    )}
                    <tr>
                      <td>Explain how the card was lost or stolen</td>
                      <td>{OrderDetails?.cardLostHow}</td>
                    </tr>
                    <tr>
                      <td>
                        Explain where the loss or theft occurred. Provide
                        Address if known (City/State)
                      </td>
                      <td>{OrderDetails?.cardLostWhere}</td>
                    </tr>
                    <tr>
                      <td>
                        On what date was your card lost or stolen (MM/DD/YYYY)?{" "}
                        <br />
                        If unknown, when was the last time you remember it in
                        your possession?
                      </td>
                      <td>
                        {OrderDetails?.cardLostDate
                          ? moment(OrderDetails?.cardLostDate).format(
                              "MM/DD/YYYY"
                            )
                          : ""}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Is the passport you are reporting lost or stolen,
                        <br /> in the possession of someone you know
                      </td>
                      <td>
                        {OrderDetails?.cardPossessionGroup === "1"
                          ? "Yes"
                          : "No"}
                      </td>
                    </tr>
                    {OrderDetails?.cardPossessionGroup === "1" && (
                      <tr>
                        <td>Who has the passport and why</td>
                        <td>{OrderDetails?.cardInPossession}</td>
                      </tr>
                    )}
                    <tr>
                      <td>
                        Have you had other U.S. passport book/card lost or
                        stolen?
                      </td>
                      <td>
                        {OrderDetails?.cardlostPrev === "1" ? "Yes" : "No"}
                      </td>
                    </tr>
                    {OrderDetails?.cardlostPrev === "1" && (
                      <>
                        <tr>
                          <td>If yes, how many valid U.S. passports?</td>
                          <td>{OrderDetails?.cardlostPrevious}</td>
                        </tr>

                        {OrderDetails?.approximateDate1 && (
                          <tr>
                            <td>Approximate date 1? (MM/DD/YYYY)</td>
                            <td>
                              {OrderDetails?.approximateDate1
                                ? moment(OrderDetails?.approximateDate1).format(
                                    "MM/DD/YYYY"
                                  )
                                : ""}
                            </td>
                          </tr>
                        )}

                        {OrderDetails?.approximateDate2 && (
                          <tr>
                            <td>Approximate date 2? (MM/DD/YYYY)</td>
                            <td>
                              {OrderDetails?.approximateDate2
                                ? moment(OrderDetails?.approximateDate2).format(
                                    "MM/DD/YYYY"
                                  )
                                : ""}
                            </td>
                          </tr>
                        )}

                        <tr>
                          <td>
                            Did you file a police report? (If yes and the report
                            is available, please submit a copy).
                          </td>
                          <td>
                            {OrderDetails?.cardlostPrev === "1" ? "Yes" : "No"}
                          </td>
                        </tr>
                      </>
                    )}
                  </tbody>
                </table>
              </div>
            </StyledOrderBlock>
          ) : null}

          {OrderDetails?.name_change_book === "1" ? (
            <StyledOrderBlock>
              <h2>Name Change Book</h2>
              <div className="table-block table-responsive">
                <table>
                  <tbody>
                    {diff <= 2 ? (
                      <>
                        {OrderDetails?.DataPrintedIncorrect &&
                          OrderDetails?.DataPrintedIncorrect === "3" && (
                            <>
                              <tr>
                                <td>
                                  Was the data printed correctly in your most
                                  recent document?{" "}
                                </td>
                                <td>"No"</td>
                              </tr>
                              <tr>
                                <td>
                                  Please select the item(s) that are printed
                                  incorrectly
                                </td>

                                <td>
                                  {OrderDetails?.IncorrectFirstName === "1"
                                    ? "First Name,"
                                    : null}
                                  {OrderDetails?.IncorrectLastName === "1"
                                    ? "Last Name,"
                                    : null}
                                  {OrderDetails?.IncorrectMiddleName === "1"
                                    ? "Middle Name,"
                                    : null}
                                  {OrderDetails?.IncorrectPlaceOfBirth === "1"
                                    ? "Place of Birth,"
                                    : null}
                                  {OrderDetails?.IncorrectDateOfBirth === "1"
                                    ? "Date of Birth,"
                                    : null}
                                  {OrderDetails?.InCorrectSex === "1"
                                    ? "Sex"
                                    : null}
                                </td>
                              </tr>
                            </>
                          )}
                        {OrderDetails?.DataPrintedIncorrect === "4" && (
                          <tr>
                            <td>
                              Was the data printed correctly in your most recent
                              document?
                            </td>
                            <td>Yes</td>
                          </tr>
                        )}

                        {OrderDetails?.NameChange === "1" ? (
                          <>
                            <tr>
                              <td>
                                Has your data changed since your most recent
                                document was issued{" "}
                              </td>
                              <td>
                                "Yes, it has changed since I got my passport
                                book"
                              </td>
                            </tr>

                            <tr>
                              <td>Reason for the name change</td>
                              <td>
                                {OrderDetails?.NameChangeReason === "M"
                                  ? "Marriage"
                                  : "Court Order"}
                              </td>
                            </tr>

                            <tr>
                              <td>Date of name change</td>
                              <td>
                                {OrderDetails?.NameChangeDate
                                  ? moment(OrderDetails?.NameChangeDate).format(
                                      "MM/DD/YYYY"
                                    )
                                  : ""}
                              </td>
                            </tr>
                            <tr>
                              <td>Place of name change(City/State)</td>
                              <td>{OrderDetails?.NameChangePlace}</td>
                            </tr>
                            <tr>
                              <td>
                                Can you submit certified documentation to
                                reflect the name change?
                              </td>
                              <td>
                                {
                                  OrderDetails?.SubmitCertifiedDocumentForNameChange
                                }
                              </td>
                            </tr>
                          </>
                        ) : (
                          <tr>
                            <td>
                              Has your name changed since your most recent
                              document was issued{" "}
                            </td>
                            <td>
                              No, it has not changed since I was issued a
                              document
                            </td>
                          </tr>
                        )}

                        {OrderDetails?.BookRecentPassportBookLimitedTwoYears && (
                          <tr>
                            <td>
                              Was your most recent passport book limited for two
                              years or less
                            </td>
                            <td>
                              {
                                OrderDetails?.BookRecentPassportBookLimitedTwoYears
                              }
                            </td>
                          </tr>
                        )}
                        {OrderDetails?.BookYouPayForACard && (
                          <tr>
                            <td>
                              Did you pay for a card the last time you applied
                            </td>
                            <td>{OrderDetails?.BookYouPayForACard}</td>
                          </tr>
                        )}
                        {OrderDetails?.BookHasYourDataChanged ? (
                          <>
                            <tr>
                              <td>
                                Has your data changed since your most recent
                                document was issued{" "}
                              </td>
                              <td>{OrderDetails?.BookHasYourDataChanged}</td>
                            </tr>
                            <tr>
                              <td>
                                Please select the item(s) that have changed
                                since your most recent document was issued
                              </td>
                              <td>
                                {bookDataChanged && bookDataChanged[0]
                                  ? `${bookDataChanged[0]},`
                                  : null}
                                {bookDataChanged && bookDataChanged[1]
                                  ? `${bookDataChanged[1]},`
                                  : null}
                                {bookDataChanged && bookDataChanged[2]
                                  ? `${bookDataChanged[2]},`
                                  : null}

                                {bookDataChanged && bookDataChanged[3]
                                  ? `${bookDataChanged[3]},`
                                  : null}

                                {bookDataChanged && bookDataChanged[4]
                                  ? `${bookDataChanged[4]},`
                                  : null}
                                {bookDataChanged && bookDataChanged[5]
                                  ? `${bookDataChanged[5]}`
                                  : null}
                              </td>
                            </tr>
                          </>
                        ) : (
                          <tr>
                            <td>
                              Has your data changed since your most recent
                              document was issued{" "}
                            </td>
                            <td>{OrderDetails?.BookHasYourDataChanged}</td>
                          </tr>
                        )}
                      </>
                    ) : (
                      <>
                        {OrderDetails?.DataPrintedIncorrect && (
                          <>
                            {OrderDetails?.DataPrintedIncorrect === "3" && (
                              <>
                                <tr>
                                  <td>
                                    Was the data printed correctly in your most
                                    recent document?{" "}
                                  </td>
                                  <td>"No"</td>
                                </tr>
                                <tr>
                                  <td>
                                    Please select the item(s) that are printed
                                    incorrectly
                                  </td>

                                  <td>
                                    {OrderDetails?.IncorrectFirstName === "1"
                                      ? "First Name,"
                                      : null}
                                    {OrderDetails?.IncorrectLastName === "1"
                                      ? "Last Name,"
                                      : null}
                                    {OrderDetails?.IncorrectMiddleName === "1"
                                      ? "Middle Name,"
                                      : null}
                                    {OrderDetails?.IncorrectPlaceOfBirth === "1"
                                      ? "Place of Birth,"
                                      : null}
                                    {OrderDetails?.IncorrectDateOfBirth === "1"
                                      ? "Date of Birth,"
                                      : null}
                                    {OrderDetails?.InCorrectSex === "1"
                                      ? "Sex"
                                      : null}
                                  </td>
                                </tr>
                              </>
                            )}

                            {OrderDetails?.DataPrintedIncorrect === "4" && (
                              <tr>
                                <td>
                                  Was the data printed correctly in your most
                                  recent document?
                                </td>
                                <td>Yes</td>
                              </tr>
                            )}

                            {OrderDetails?.NameChange === "1" ? (
                              <>
                                <tr>
                                  <td>
                                    Has your data changed since your most recent
                                    document was issued{" "}
                                  </td>
                                  <td>
                                    "Yes, it has changed since I got my passport
                                    book"
                                  </td>
                                </tr>

                                <tr>
                                  <td>Reason for the name change</td>
                                  <td>
                                    {OrderDetails?.NameChangeReason === "M"
                                      ? "Marriage"
                                      : "Court Order"}
                                  </td>
                                </tr>

                                <tr>
                                  <td>Date of name change</td>
                                  <td>
                                    {OrderDetails?.NameChangeDate
                                      ? moment(
                                          OrderDetails?.NameChangeDate
                                        ).format("MM/DD/YYYY")
                                      : ""}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Place of name change(City/State)</td>
                                  <td>{OrderDetails?.NameChangePlace}</td>
                                </tr>
                                <tr>
                                  <td>
                                    Can you submit certified documentation to
                                    reflect the name change?
                                  </td>
                                  <td>
                                    {
                                      OrderDetails?.SubmitCertifiedDocumentForNameChange
                                    }
                                  </td>
                                </tr>
                              </>
                            ) : (
                              <tr>
                                <td>
                                  Has your name changed since your most recent
                                  document was issued{" "}
                                </td>
                                <td>
                                  No, it has not changed since I was issued a
                                  document
                                </td>
                              </tr>
                            )}

                            {OrderDetails?.BookRecentPassportBookLimitedTwoYears && (
                              <tr>
                                <td>
                                  Was your most recent passport book limited for
                                  two years or less
                                </td>
                                <td>
                                  {
                                    OrderDetails?.BookRecentPassportBookLimitedTwoYears
                                  }
                                </td>
                              </tr>
                            )}

                            {OrderDetails?.BookYouPayForACard && (
                              <tr>
                                <td>
                                  Did you pay for a card the last time you
                                  applied
                                </td>
                                <td>{OrderDetails?.BookYouPayForACard}</td>
                              </tr>
                            )}

                            {OrderDetails?.BookHasYourDataChanged ? (
                              <>
                                <tr>
                                  <td>
                                    Has your data changed since your most recent
                                    document was issued{" "}
                                  </td>
                                  <td>
                                    {OrderDetails?.BookHasYourDataChanged}
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    Please select the item(s) that have changed
                                    since your most recent document was issued
                                  </td>
                                  <td>
                                    {bookDataChanged && bookDataChanged[0]
                                      ? `${bookDataChanged[0]},`
                                      : null}
                                    {bookDataChanged && bookDataChanged[1]
                                      ? `${bookDataChanged[1]},`
                                      : null}
                                    {bookDataChanged && bookDataChanged[2]
                                      ? `${bookDataChanged[2]},`
                                      : null}

                                    {bookDataChanged && bookDataChanged[3]
                                      ? `${bookDataChanged[3]},`
                                      : null}

                                    {bookDataChanged && bookDataChanged[4]
                                      ? `${bookDataChanged[4]},`
                                      : null}
                                    {bookDataChanged && bookDataChanged[5]
                                      ? `${bookDataChanged[5]}`
                                      : null}
                                  </td>
                                </tr>
                              </>
                            ) : (
                              <tr>
                                <td>
                                  Has your data changed since your most recent
                                  document was issued{" "}
                                </td>
                                <td>{OrderDetails?.BookHasYourDataChanged}</td>
                              </tr>
                            )}

                            {OrderDetails?.Statusyourtransition && (
                              <tr>
                                <td>
                                  Status of your transition from male to female
                                  or female to male
                                </td>
                                <td>{OrderDetails?.Statusyourtransition}</td>
                              </tr>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </tbody>
                </table>
              </div>
            </StyledOrderBlock>
          ) : null}

          {OrderDetails?.name_change_card &&
            OrderDetails?.name_change_card === "1" && (
              <StyledOrderBlock>
                <h2>Name Change Card</h2>

                <div className="table-block table-responsive">
                  <table>
                    <tbody>
                      {OrderDetails?.DataPrintedIncorrect === "3" && (
                        <>
                          <tr>
                            <td>
                              Was the data printed correctly in your most recent
                              document?
                            </td>
                            <td>No</td>
                          </tr>

                          <tr>
                            <td>
                              Please select the item(s) that are printed
                              incorrectly
                            </td>

                            <td>
                              {OrderDetails?.IncorrectFirstName === "1"
                                ? "First Name,"
                                : null}
                              {OrderDetails?.IncorrectLastName === "1"
                                ? "Last Name,"
                                : null}
                              {OrderDetails?.IncorrectMiddleName === "1"
                                ? "Middle Name,"
                                : null}
                              {OrderDetails?.IncorrectPlaceOfBirth === "1"
                                ? "Place of Birth,"
                                : null}
                              {OrderDetails?.IncorrectDateOfBirth === "1"
                                ? "Date of Birth,"
                                : null}
                              {OrderDetails?.InCorrectSex === "1"
                                ? "Sex"
                                : null}
                            </td>
                          </tr>
                        </>
                      )}

                      {OrderDetails?.DataPrintedIncorrect === "4" && (
                        <tr>
                          <td>
                            Was the data printed correctly in your most recent
                            document?
                          </td>
                          <td>Yes</td>
                        </tr>
                      )}

                      {OrderDetails?.NameChange === "3" ? (
                        <>
                          <tr>
                            <td>
                              Has your name changed since your most recent
                              document was issued?
                            </td>
                            <td>
                              Yes, it has changed since I got my passport card
                            </td>
                          </tr>
                          <tr>
                            <td>Reason for the name change</td>
                            <td>
                              {OrderDetails?.NameChangeReason === "M"
                                ? "Marriage"
                                : "Court Order"}
                            </td>
                          </tr>
                          <tr>
                            <td>Date of name change</td>
                            {OrderDetails?.NameChangeDate
                              ? moment(OrderDetails?.NameChangeDate).format(
                                  "MM/DD/YYYY"
                                )
                              : ""}{" "}
                          </tr>
                          <tr>
                            <td>Place of name change(City/State)</td>
                            <td>{OrderDetails?.NameChangePlace}</td>
                          </tr>
                          <tr>
                            <td>
                              Can you submit certified documentation to reflect
                              the name change?
                            </td>
                            <td>
                              {
                                OrderDetails?.SubmitCertifiedDocumentForNameChange
                              }
                            </td>
                          </tr>
                        </>
                      ) : (
                        <tr>
                          <td>
                            Has your name changed since your most recent
                            document was issued?
                          </td>
                          <td>
                            No, it has not changed since I was issued a document
                          </td>
                        </tr>
                      )}

                      {OrderDetails?.CardHasYourDataChanged === "Yes" ? (
                        <>
                          <tr>
                            <td>
                              Has your data changed since your most recent
                              document was issued
                            </td>
                            <td>{OrderDetails?.CardHasYourDataChanged}</td>
                          </tr>
                          <tr>
                            <td>
                              Please select the item(s) that have changed since
                              your most recent document was issued
                            </td>
                            <td>
                              {cardDataChanged && cardDataChanged[0]
                                ? `${cardDataChanged[0]},`
                                : null}
                              {cardDataChanged && cardDataChanged[1]
                                ? `${cardDataChanged[1]},`
                                : null}
                              {cardDataChanged && cardDataChanged[2]
                                ? `${cardDataChanged[2]},`
                                : null}
                              {cardDataChanged && cardDataChanged[3]
                                ? `${cardDataChanged[3]},`
                                : null}
                              {cardDataChanged && cardDataChanged[4]
                                ? `${cardDataChanged[4]},`
                                : null}
                              {cardDataChanged && cardDataChanged[5]
                                ? `${cardDataChanged[5]}`
                                : null}
                            </td>
                          </tr>
                        </>
                      ) : (
                        <tr>
                          <td>
                            Has your data changed since your most recent
                            document was issued
                          </td>
                          <td>{OrderDetails?.CardHasYourDataChanged}</td>
                        </tr>
                      )}

                      {OrderDetails?.Statusyourtransition && (
                        <tr>
                          <td>
                            Status of your transition from male to female or
                            female to male
                          </td>
                          <td>{OrderDetails?.Statusyourtransition}</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </StyledOrderBlock>
            )}

          {OrderDetails?.name_change_both === "1" ? (
            <StyledOrderBlock>
              <h2>Name Change Both</h2>
              <div className="table-block table-responsive">
                <table>
                  <tbody>
                    {OrderDetails?.DataPrintedIncorrect && (
                      <tr>
                        <td>
                          Was the data printed correctly in your most recent
                          document
                        </td>
                        <td>
                          {OrderDetails?.DataPrintedIncorrect === "1"
                            ? "No, it was printed incorrectly on my passport book"
                            : OrderDetails?.DataPrintedIncorrect === "2"
                            ? "No, it was printed incorrectly on my passport book and card"
                            : OrderDetails?.DataPrintedIncorrect === "3"
                            ? "No, it was printed incorrectly on my passport card"
                            : OrderDetails?.DataPrintedIncorrect === "4"
                            ? "Yes, it was printed correctly"
                            : null}
                        </td>
                      </tr>
                    )}
                    {OrderDetails?.DataPrintedIncorrect !== "4" && (
                      <tr>
                        <td>
                          Please select the item(s) that are printed incorrectly
                        </td>

                        <td>
                          {OrderDetails?.IncorrectFirstName === "2"
                            ? "First Name,"
                            : null}
                          {OrderDetails?.IncorrectLastName === "1"
                            ? "Last Name,"
                            : null}
                          {OrderDetails?.IncorrectMiddleName === "3"
                            ? "Middle Name,"
                            : null}
                          {OrderDetails?.IncorrectPlaceOfBirth === "4"
                            ? "Place of Birth,"
                            : null}
                          {OrderDetails?.IncorrectDateOfBirth === "5"
                            ? "Date of Birth,"
                            : null}
                          {OrderDetails?.InCorrectSex === "6" ? "Sex" : null}
                        </td>
                      </tr>
                    )}

                    {OrderDetails?.NameChange !== null && (
                      <tr>
                        <td>
                          Has your name changed since your most recent document
                          was issued
                        </td>
                        <td>
                          {OrderDetails?.NameChange === "1"
                            ? "Yes, it has changed since I got my passport book"
                            : OrderDetails?.NameChange === "2"
                            ? "Yes, it has changed since I got my passport book and card"
                            : OrderDetails?.NameChange === "3"
                            ? "Yes, it has changed since I got my passport card"
                            : OrderDetails?.NameChange === "4"
                            ? "No, it has not changed since I was issued a document"
                            : null}
                        </td>
                      </tr>
                    )}

                    {OrderDetails?.NameChange !== "4" && (
                      <>
                        {OrderDetails?.NameChangeReason && (
                          <tr>
                            <td>Reason for the name change</td>
                            <td>
                              {OrderDetails?.NameChangeReason === "M"
                                ? "Marriage"
                                : "Court Order"}
                            </td>
                          </tr>
                        )}

                        <tr>
                          <td>Date of name change</td>
                          <td>
                            {OrderDetails?.NameChangeDate
                              ? moment(OrderDetails?.NameChangeDate).format(
                                  "MM/DD/YYYY"
                                )
                              : ""}
                          </td>
                        </tr>

                        <tr>
                          <td>Place of name change(City/State)</td>
                          <td>{OrderDetails?.NameChangePlace}</td>
                        </tr>
                      </>
                    )}
                    {OrderDetails?.BookRecentPassportBookLimitedTwoYears && (
                      <tr>
                        <td>
                          Was your most recent passport book limited for two
                          years or less
                        </td>
                        <td>
                          {OrderDetails?.BookRecentPassportBookLimitedTwoYears}
                        </td>
                      </tr>
                    )}

                    {OrderDetails?.BookYouPayForACard && (
                      <tr>
                        <td>
                          Did you pay for a card the last time you applied
                        </td>
                        <td>{OrderDetails?.BookYouPayForACard}</td>
                      </tr>
                    )}

                    {OrderDetails?.BookHasYourDataChanged === "Yes" ? (
                      <>
                        <tr>
                          <td>
                            Has your data changed since your most recent
                            document was issued
                          </td>
                          <td>{OrderDetails?.BookHasYourDataChanged}</td>
                        </tr>
                        <tr>
                          <td>
                            Please select the item(s) that have changed since
                            your most recent document was issued
                          </td>
                          <td>
                            {bookDataChanged && bookDataChanged[0]
                              ? `${bookDataChanged[0]},`
                              : null}
                            {bookDataChanged && bookDataChanged[1]
                              ? `${bookDataChanged[1]},`
                              : null}
                            {bookDataChanged && bookDataChanged[2]
                              ? `${bookDataChanged[2]},`
                              : null}

                            {bookDataChanged && bookDataChanged[3]
                              ? `${bookDataChanged[3]},`
                              : null}

                            {bookDataChanged && bookDataChanged[4]
                              ? `${bookDataChanged[4]},`
                              : null}
                            {bookDataChanged && bookDataChanged[5]
                              ? `${bookDataChanged[5]},`
                              : null}
                          </td>
                        </tr>
                      </>
                    ) : OrderDetails?.CardHasYourDataChanged === "Yes" ? (
                      <>
                        <tr>
                          <td>
                            Has your data changed since your most recent
                            document was issued
                          </td>
                          <td>{OrderDetails?.CardHasYourDataChanged}</td>
                        </tr>
                        <tr>
                          <td>
                            Please select the item(s) that have changed since
                            your most recent document was issued
                          </td>
                          <td>
                            {cardDataChanged && cardDataChanged[0]
                              ? `${cardDataChanged[0]},`
                              : null}
                            {cardDataChanged && cardDataChanged[1]
                              ? `${cardDataChanged[1]},`
                              : null}
                            {cardDataChanged && cardDataChanged[2]
                              ? `${cardDataChanged[2]},`
                              : null}
                            {cardDataChanged && cardDataChanged[3]
                              ? `${cardDataChanged[3]},`
                              : null}
                            {cardDataChanged && cardDataChanged[4]
                              ? `${cardDataChanged[4]},`
                              : null}
                            {cardDataChanged && cardDataChanged[5]
                              ? `${cardDataChanged[5]}`
                              : null}
                          </td>
                        </tr>
                      </>
                    ) : (
                      <tr>
                        <td>
                          Has your data changed since your most recent document
                          was issued
                        </td>
                        <td>No</td>
                      </tr>
                    )}

                    {OrderDetails?.Statusyourtransition !== "" && (
                      <tr>
                        <td>
                          Status of your transition from male to female or
                          female to male
                        </td>
                        <td>{OrderDetails?.Statusyourtransition}</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </StyledOrderBlock>
          ) : null}

          {OrderDetails?.lost_stolen_both === "1" && (
            <StyledOrderBlock>
              <h2>Lost Stolen Both</h2>
              <div className="table-block table-responsive">
                <table>
                  <tbody>
                    <tr>
                      <td colSpan="2" className="order-detail-header">
                        <b style={{ fontSize: "19px" }}>
                          <u>Lost Or Stolen Passport Information</u>
                        </b>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Are you reporting your own lost or stolen passport
                      </td>
                      <td>
                        {OrderDetails?.lostStolenStep === "1"
                          ? "Yes"
                          : OrderDetails?.lostStolenStep === "2"
                          ? "No"
                          : null}
                      </td>
                    </tr>

                    <tr>
                      <td>
                        Did you file a police report? (If yes and the report is
                        available, please submit a copy).
                      </td>
                      <td>
                        {OrderDetails?.policeReport1 === "1" ? "Yes" : "No"}
                      </td>
                    </tr>

                    {OrderDetails?.lostStolenStep &&
                      OrderDetails?.lostStolenStep === "2" && (
                        <>
                          <tr>
                            <td>Reporter First Name</td>
                            <td>{OrderDetails?.reporterFirstName}</td>
                          </tr>
                          <tr>
                            <td>Reporter Middle Name</td>
                            <td>{OrderDetails?.reporterMiddleName}</td>
                          </tr>
                          <tr>
                            <td>Reporter Last Name</td>
                            <td>{OrderDetails?.reporterLastName}</td>
                          </tr>
                          <tr>
                            <td>
                              What is the reporter's relationship to the owner
                              of the passport
                            </td>
                            <td>{OrderDetails?.reporterRelationship}</td>
                          </tr>
                        </>
                      )}
                    <tr>
                      <td>
                        On what date was the book lost or stolen
                        <br /> (MM/DD/YYYY)? If unknown, when was the last time
                        you remember it in your possession?
                      </td>
                      <td>
                        {OrderDetails?.bookLostDate
                          ? moment(OrderDetails?.bookLostDate).format(
                              "MM/DD/YYYY"
                            )
                          : ""}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Explain where the loss or theft occurred. Provide
                        Address if known (City/State)
                      </td>
                      <td>{OrderDetails?.bookLostWhere}</td>
                    </tr>
                    <tr>
                      <td>Explain how the book was lost or stolen</td>
                      <td>{OrderDetails?.bookLostHow}</td>
                    </tr>
                    <tr>
                      <td>
                        Did you lose your book and card at the same time and
                        place
                      </td>
                      <td>
                        {OrderDetails?.loseSamePlace === "1" ? "Yes" : "No"}
                      </td>
                    </tr>
                    {OrderDetails?.loseSamePlace === "1" ? (
                      <>
                        <tr>
                          <td>
                            What date was your book and card lost or stolen
                          </td>
                          <td>
                            {OrderDetails?.bookcardLostDate
                              ? moment(OrderDetails?.bookcardLostDate).format(
                                  "MM/DD/YYYY"
                                )
                              : ""}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Explain where the loss or theft occurred. Provide
                            Address if known (City/State)
                          </td>
                          <td>{OrderDetails?.bookcardLostWhere}</td>
                        </tr>
                        <tr>
                          <td>
                            On what date was your document(s) lost or stolen
                            (MM/DD/YYYY)? If unknown, when was the last time you
                            remember it in your possession?
                          </td>
                          <td>{OrderDetails?.bookcardLostHow}</td>
                        </tr>
                      </>
                    ) : (
                      <>
                        <tr>
                          <td>
                            On what date was the book lost or stolen
                            (MM/DD/YYYY)? <br />
                            If unknown, when was the last time you remember it
                            in your possession?
                          </td>
                          <td>
                            {OrderDetails?.bookLostDate
                              ? moment(OrderDetails?.bookLostDate).format(
                                  "MM/DD/YYYY"
                                )
                              : ""}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Explain where the loss or theft occurred. Provide
                            Address if known (City/State)
                          </td>
                          <td>{OrderDetails?.bookLostWhere}</td>
                        </tr>
                        <tr>
                          <td>Explain how the book was lost or stolen.</td>
                          <td>{OrderDetails?.bookLostHow}</td>
                        </tr>
                        <tr>
                          <td>
                            On what date was your card lost or stolen
                            (MM/DD/YYYY)? <br />
                            If unknown, when was the last time you remember it
                            in your possession?
                          </td>
                          <td>
                            {OrderDetails?.cardLostDate
                              ? moment(OrderDetails?.cardLostDate).format(
                                  "MM/DD/YYYY"
                                )
                              : ""}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Explain where the loss or theft occurred. Provide
                            Address if known (City/State)
                          </td>
                          <td>{OrderDetails?.cardLostWhere}</td>
                        </tr>
                        <tr>
                          <td>Explain how the card was lost or stolen</td>
                          <td>{OrderDetails?.cardLostHow}</td>
                        </tr>
                      </>
                    )}
                    {OrderDetails?.PossessionGroup === "1" && (
                      <tr>
                        <td>Who has the passport and why</td>
                        <td>{OrderDetails?.InPossession}</td>
                      </tr>
                    )}
                    <tr>
                      <td>
                        Have you had other U.S. passport book/card lost or
                        stolen?
                      </td>
                      <td>{OrderDetails?.lostPrev === "1" ? "Yes" : "No"}</td>
                    </tr>

                    {OrderDetails?.lostPrev === "1" && (
                      <>
                        <tr>
                          <td>If yes, how many valid U.S. passports?</td>
                          <td>{OrderDetails?.lostPrevious}</td>
                        </tr>

                        {OrderDetails?.approximateDate1 && (
                          <tr>
                            <td>Approximate date 1? (MM/DD/YYYY)</td>
                            <td>
                              {OrderDetails?.approximateDate1
                                ? moment(OrderDetails?.approximateDate1).format(
                                    "MM/DD/YYYY"
                                  )
                                : ""}
                            </td>
                          </tr>
                        )}

                        {OrderDetails?.approximateDate2 && (
                          <tr>
                            <td>Approximate date 2? (MM/DD/YYYY)</td>
                            <td>
                              {OrderDetails?.approximateDate2
                                ? moment(OrderDetails?.approximateDate2).format(
                                    "MM/DD/YYYY"
                                  )
                                : ""}
                            </td>
                          </tr>
                        )}
                        <tr>
                          <td>
                            Did you file a police report? (If yes and the report
                            is available, please submit a copy).
                          </td>
                          <td>
                            {OrderDetails?.lostPrev === "1" ? "Yes" : "No"}
                          </td>
                        </tr>
                      </>
                    )}
                  </tbody>
                </table>
              </div>
            </StyledOrderBlock>
          )}

          {OrderDetails?.lost_stolen_book === "1" && (
            <StyledOrderBlock>
              <h2>Lost Stolen Book</h2>
              <div className="table-block table-responsive">
                <table>
                  <tbody>
                    <>
                      <tr>
                        <td>
                          Are you reporting your own lost or stolen passport?
                        </td>
                        <td>
                          {OrderDetails?.lostStolenStep === "1"
                            ? "Yes"
                            : OrderDetails?.lostStolenStep === "2"
                            ? "No"
                            : null}
                        </td>
                      </tr>

                      <tr>
                        <td>
                          Did you file a police report? (If yes and the report
                          is available, please submit a copy).
                        </td>
                        <td>
                          {OrderDetails?.policeReport1 === "1" ? "Yes" : "No"}
                        </td>
                      </tr>

                      {OrderDetails?.lostStolenStep &&
                        OrderDetails?.lostStolenStep === "2" && (
                          <>
                            <tr>
                              <td>Reporter First Name</td>
                              <td>{OrderDetails?.reporterFirstName}</td>
                            </tr>

                            <tr>
                              <td>Reporter Middle Name</td>
                              <td>{OrderDetails?.reporterMiddleName}</td>
                            </tr>

                            <tr>
                              <td>Reporter Last Name</td>
                              <td>{OrderDetails?.reporterLastName}</td>
                            </tr>

                            <tr>
                              <td>
                                What is the reporter's relationship to the owner
                                of the passport
                              </td>
                              <td>{OrderDetails?.reporterRelationship}</td>
                            </tr>
                          </>
                        )}

                      <tr>
                        <td>Explain how the book was lost or stolen.</td>

                        <td>{OrderDetails?.bookLostHow}</td>
                      </tr>

                      <tr>
                        <td>
                          Explain where the loss or theft occurred. Provide
                          Address if known (City/State)
                        </td>

                        <td>{OrderDetails?.bookLostWhere}</td>
                      </tr>

                      {OrderDetails?.PossessionGroup &&
                        OrderDetails?.PossessionGroup === "1" && (
                          <tr>
                            <td>Who has the passport and why</td>
                            <td>{OrderDetails?.InPossession}</td>
                          </tr>
                        )}

                      <tr>
                        <td>
                          On what date was the book lost or stolen (MM/DD/YYYY)?
                          <br /> If unknown, when was the last time you remember
                          it in your possession?
                        </td>
                        <td>
                          {OrderDetails?.bookLostDate
                            ? moment(OrderDetails?.bookLostDate).format(
                                "MM/DD/YYYY"
                              )
                            : ""}
                        </td>
                      </tr>

                      <tr>
                        <td>
                          Have you had other U.S. passport book/card lost or
                          stolen?
                        </td>
                        <td>{OrderDetails?.lostPrev === "1" ? "Yes" : "No"}</td>
                      </tr>

                      {OrderDetails?.lostPrev === "1" && (
                        <>
                          <tr>
                            <td>If yes, how many valid U.S. passports?</td>
                            <td>{OrderDetails?.lostPrevious}</td>
                          </tr>

                          {OrderDetails?.approximateDate1 && (
                            <tr>
                              <td>Approximate date 1? (MM/DD/YYYY)</td>
                              <td>
                                {OrderDetails?.approximateDate1
                                  ? moment(
                                      OrderDetails?.approximateDate1
                                    ).format("MM/DD/YYYY")
                                  : ""}
                              </td>
                            </tr>
                          )}

                          {OrderDetails?.approximateDate2 && (
                            <tr>
                              <td>Approximate date 2? (MM/DD/YYYY)</td>
                              <td>
                                {OrderDetails?.approximateDate2
                                  ? moment(
                                      OrderDetails?.approximateDate2
                                    ).format("MM/DD/YYYY")
                                  : ""}
                              </td>
                            </tr>
                          )}
                          <tr>
                            <td>
                              Did you file a police report? (If yes and the
                              report is available, please submit a copy).
                            </td>
                            <td>
                              {OrderDetails?.lostPrev === "1" ? "Yes" : "No"}
                            </td>
                          </tr>
                        </>
                      )}
                    </>
                  </tbody>
                </table>
              </div>
            </StyledOrderBlock>
          )}

          {OrderDetails?.parent_spouse === "1" && (
            <StyledOrderBlock>
              <h2>Parent & Spouse Information</h2>
              <div className="row">
                <div className="col-md-6 pdr-0">
                  <div className="table-block table-responsive">
                    <table>
                      <tbody>
                        {OrderDetails?.Parent1CheckBox !== "1" ? (
                          <>
                            <tr>
                              <td> First & Middle Name </td>
                              <td>{`${OrderDetails?.parent1FirstName} ${OrderDetails?.parent1MiddleName}`}</td>
                            </tr>

                            <tr>
                              <td>Date of Birth</td>
                              <td>
                                {OrderDetails?.parent1BirthDate
                                  ? moment(
                                      OrderDetails?.parent1BirthDate
                                    ).format("MM/DD/YYYY")
                                  : ""}
                              </td>
                            </tr>
                            <tr>
                              <td>Gender</td>
                              <td>{OrderDetails?.parent1SexList}</td>
                            </tr>

                            <tr>
                              <td colSpan="2">&nbsp;</td>
                            </tr>
                          </>
                        ) : (
                          <>
                            <tr>
                              <td colSpan="2" className="order-detail-header">
                                <b>Unknown</b>
                              </td>
                            </tr>
                          </>
                        )}

                        {OrderDetails?.Parent2CheckBox !== "1" ? (
                          <>
                            <tr>
                              <td> First & Middle Name </td>
                              <td>{`${OrderDetails?.parent2FirstName} ${OrderDetails?.parent2MiddleName}`}</td>
                            </tr>

                            <tr>
                              <td>Date of Birth</td>
                              <td>
                                {OrderDetails?.parent2BirthDate
                                  ? moment(
                                      OrderDetails?.parent2BirthDate
                                    ).format("MM/DD/YYYY")
                                  : ""}
                              </td>
                            </tr>
                            <tr>
                              <td>Gender</td>
                              <td>{OrderDetails?.parent2SexList}</td>
                            </tr>
                          </>
                        ) : (
                          <>
                            <tr>
                              <td colSpan="2" className="order-detail-header">
                                <b>Unknown</b>
                              </td>
                            </tr>
                          </>
                        )}

                        <tr>
                          <td>Have You Ever Been Married </td>
                          <td>{OrderDetails?.marriedList}</td>
                        </tr>

                        {OrderDetails?.marriedList === "Yes" ? (
                          <>
                            <tr>
                              <td>Spouse's Or Former Spouse's Full Name</td>
                              <td>{OrderDetails?.spouseName}</td>
                            </tr>
                            <tr>
                              <td>First & Middle Name</td>
                              <td>{OrderDetails?.spouseFirstAndMiddleName}</td>
                            </tr>
                            <tr>
                              <td>Last Name</td>
                              <td>{OrderDetails?.spouseLastName}</td>
                            </tr>
                            <tr>
                              <td>Place Of Birth</td>
                              <td>{OrderDetails?.spouseBirthplace}</td>
                            </tr>

                            <tr>
                              <td>Date Of Most Recent Marriage</td>
                              <td>
                                {OrderDetails?.marriedDate
                                  ? moment(OrderDetails?.marriedDate).format(
                                      "MM/DD/YYYY"
                                    )
                                  : ""}
                              </td>
                            </tr>
                            <tr>
                              <td>Have you ever been widowed or divorced</td>
                              <td>{OrderDetails?.divorcedList}</td>
                            </tr>
                          </>
                        ) : null}

                        {OrderDetails?.marriedList === "Yes" &&
                          OrderDetails?.divorcedList === "Yes" && (
                            <tr>
                              {" "}
                              <td>Date</td>{" "}
                              <td>
                                {OrderDetails?.divorcedDate
                                  ? moment(OrderDetails?.divorcedDate).format(
                                      "MM/DD/YYYY"
                                    )
                                  : ""}
                              </td>
                            </tr>
                          )}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="col-md-6 pdl-0">
                  <div className="table-block table-responsive">
                    <table>
                      <tbody>
                        {OrderDetails?.Parent1CheckBox !== "1" ? (
                          <>
                            <tr>
                              <td> Last Name</td>
                              <td>{OrderDetails?.parent1LastName}</td>
                            </tr>

                            <tr>
                              <td>Place of Birth</td>
                              <td>{OrderDetails?.parent1BirthPlace}</td>
                            </tr>

                            <tr>
                              <td>U.S. Citizen</td>
                              <td>{OrderDetails?.parent1CitizenList}</td>
                            </tr>

                            <tr>
                              <td colSpan="2">&nbsp;</td>
                            </tr>
                          </>
                        ) : (
                          <>
                            <tr>
                              <td colSpan="2"></td>
                            </tr>
                          </>
                        )}

                        {OrderDetails?.Parent2CheckBox !== "1" ? (
                          <>
                            <tr>
                              <td> Last Name </td>
                              <td>{OrderDetails?.parent2LastName}</td>
                            </tr>

                            <tr>
                              <td>Place of Birth</td>
                              <td>{OrderDetails?.parent2BirthPlace}</td>
                            </tr>
                            <tr>
                              <td>U.S. Citizen</td>
                              <td>{OrderDetails?.parent2CitizenList}</td>
                            </tr>

                            <tr>
                              <td colSpan="2">&nbsp;</td>
                            </tr>
                          </>
                        ) : (
                          <>
                            <tr>
                              <td colSpan="2"></td>
                            </tr>
                          </>
                        )}

                        <tr>
                          <td colSpan="2">&nbsp;</td>
                        </tr>
                        {OrderDetails?.marriedList === "Yes" ? (
                          <>
                            <tr>
                              <td>Date Of Birth</td>
                              <td>
                                {OrderDetails?.spouseBirthDate
                                  ? moment(
                                      OrderDetails?.spouseBirthDate
                                    ).format("MM/DD/YYYY")
                                  : ""}
                              </td>
                            </tr>

                            <tr>
                              <td>U.S. Citizen</td>
                              <td>{OrderDetails?.spouseCitizenList}</td>
                            </tr>
                          </>
                        ) : null}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </StyledOrderBlock>
          )}

          <StyledOrderBlock>
            <h2>Are you known by other names</h2>
            {OrderDetails?.other_name === "1" ? (
              <div className="table-block table-responsive">
                <table>
                  <tbody>
                    <tr>
                      <td>Other First Names 1</td>
                      <td>
                        {OrderDetails?.OtherFirstname_1 !== ""
                          ? OrderDetails?.OtherFirstname_1
                          : "No"}
                      </td>
                    </tr>

                    <tr>
                      <td>Other Last Names 1</td>
                      <td>
                        {OrderDetails?.OtherLastname_1 !== ""
                          ? OrderDetails?.OtherLastname_1
                          : "No"}
                      </td>
                    </tr>

                    {OrderDetails?.OtherFirstname_2 !== "" && (
                      <tr>
                        <td>Other First Names 2</td>
                        <td>{OrderDetails?.OtherFirstname_2}</td>
                      </tr>
                    )}

                    {OrderDetails?.OtherLastname_2 !== "" && (
                      <tr>
                        <td>Other Last Names 2</td>
                        <td>{OrderDetails?.OtherLastname_2}</td>
                      </tr>
                    )}

                    {OrderDetails?.OtherFirstname_3 !== "" && (
                      <tr>
                        <td>Other First Names 3</td>
                        <td>{OrderDetails?.OtherFirstname_3}</td>
                      </tr>
                    )}

                    {OrderDetails?.OtherLastname_3 !== "" && (
                      <tr>
                        <td>Other Last Names 3</td>
                        <td>{OrderDetails?.OtherLastname_3}</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            ) : (
              <>
                <tr>
                  <td>Other First Names</td>
                  <td> No</td>
                </tr>
                <tr>
                  <td>Other Second Names</td>
                  <td> No</td>
                </tr>
              </>
            )}
          </StyledOrderBlock>

          <StyledOrderBlock>
            <h2>Type of Passport Ordered</h2>
            <div className="table-block table-responsive">
              <table>
                <tbody>
                  <tr>
                    <td className="order-detail-header">
                      Type of Passport Ordered
                    </td>
                    <td>
                      {OrderDetails?.ppptypex === "1"
                        ? "Passport Book"
                        : OrderDetails?.ppptypex === "2"
                        ? "Passport Card"
                        : OrderDetails?.ppptypex === "3"
                        ? "Passport Book and Card"
                        : null}
                    </td>
                  </tr>
                  <tr>
                    <td>US Date</td>
                    <td>
                      {OrderDetails?.us_date
                        ? moment(OrderDetails?.us_date).format("MM/DD/YYYY")
                        : ""}
                    </td>
                  </tr>
                  <tr>
                    <td>US Time - EST</td>
                    <td>{OrderDetails?.us_time}</td>
                  </tr>
                  <tr>
                    <td>IP Address</td>
                    <td>{OrderDetails?.ip}</td>
                  </tr>
                  {OrderDetails?.user_device_detail && (
                    <>
                      <tr>
                        <td>Device</td>
                        <td>{userDetail?.device_type}</td>
                      </tr>
                      <tr>
                        <td>Device Name</td>
                        <td>
                          {userDetail?.device_name
                            ? userDetail?.device_name
                            : userDetail?.device_type === "smartphone"
                            ? "Phone"
                            : "Desktop/Laptop"}
                        </td>
                      </tr>
                      <tr>
                        <td>Os-Version</td>
                        <td>{`${userDetail?.os} ${userDetail?.os_version}`}</td>
                      </tr>
                      <tr>
                        <td>Browser-Version</td>
                        <td>{`${userDetail?.browser_name} ${userDetail?.browser_version}`}</td>
                      </tr>
                    </>
                  )}

                  {OrderDetails?.d_sign && (
                    <tr>
                      <td>View Digital Signature </td>
                      <td>
                        <a
                          href={
                            `${process.env.REACT_APP_BASE_URL}/${process.env.REACT_APP_PUBLIC_UPLOAD_PATH}${OrderDetails?.d_sign}`
                              ? `${process.env.REACT_APP_BASE_URL}/${process.env.REACT_APP_PUBLIC_UPLOAD_PATH}${OrderDetails?.d_sign}`
                              : `https://usa-passport.com/${OrderDetails?.d_sign}`
                          }
                          target="_blank"
                          rel="noreferrer"
                        >
                          Click Here
                        </a>
                      </td>
                    </tr>
                  )}

                  <tr>
                    <td>Passport Book Size </td>
                    <td>{OrderDetails?.passport_book_size}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </StyledOrderBlock>
          {OrderDetails?.downloadHistory && (
            <StyledOrderBlock>
              <h2>Download History</h2>
              <div className="table-block table-responsive">
                <table width="100%">
                  <thead>
                    <tr>
                      <th width="5%">#</th>
                      <th width="10%">Order ID</th>
                      <th width="22%">Date & Time</th>
                      <th width="23%">Downloaded File</th>
                      <th width="20%">IP Address</th>
                      <th width="20%">Location</th>
                    </tr>
                  </thead>
                  <tbody>
                    {OrderDetails?.downloadHistory ? (
                      OrderDetails?.downloadHistory?.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>
                              {`${OrderDetails?.pre_id}${item?.order_id}`}
                            </td>
                            <td>
                              {item?.create_ts
                                ? moment(item?.create_ts).format(
                                    "MM/DD/YYYY h:mm:ss"
                                  )
                                : ""}{" "}
                              EST
                            </td>
                            {OrderDetails?.uploadDoc?.length ? (
                              <td>
                                <a
                                  href={
                                    OrderDetails?.length !== 0
                                      ? `${process.env.REACT_APP_BASE_URL}/${process.env.REACT_APP_PUBLIC_UPLOAD_PATH}docs/${OrderDetails?.uploadDoc[0]?.file1}`
                                        ? `${process.env.REACT_APP_BASE_URL}/${process.env.REACT_APP_PUBLIC_UPLOAD_PATH}docs/${OrderDetails?.uploadDoc[0]?.file1}`
                                        : `https://usa-passport.com/uploads/${OrderDetails?.uploadDoc[0]?.file1}`
                                      : "#"
                                  }
                                  className="blue-btn"
                                  download
                                  rel="noreferrer"
                                >
                                  Download File{" "}
                                  <span>
                                    <FaDownload className="download-icons" />
                                  </span>
                                </a>
                              </td>
                            ) : (
                              ""
                            )}
                            <td>{item?.ip}</td>
                            <td>{item?.timezone}</td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan="4" align="center">
                          No record found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
                <form className="form-inline">
                  <div className="form-group">
                    <label>
                      <input
                        type="email"
                        name="email"
                        id="email"
                        className="form-control input-class"
                        value={emailId}
                        onChange={(e) => setEmailId(e.target.value)}
                      />
                    </label>
                  </div>
                  <div className="form-group">
                    <button
                      type="submit"
                      className="blue-btn"
                      id="download_btn"
                      onClick={handleResendMail}
                      disabled={isLoading}
                    >
                      Sent Mail{" "}
                      <span>
                        <img src={rightArrow} alt="rightArrow" />
                      </span>
                    </button>
                  </div>
                </form>
              </div>
            </StyledOrderBlock>
          )}

          <AddComment />
          <StyledOrderBlock>
            <h2>Comment History</h2>
            <div className="table-block table-responsive">
              <table>
                <thead>
                  <tr>
                    <th width="10%">#</th>
                    <th width="20%">Order ID</th>
                    <th width="20%">Date & Time</th>
                    <th width="15%">Team</th>
                    <th width="35%">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {OrderDetails?.remarkModel ? (
                    OrderDetails?.remarkModel?.map((item, i) => {
                      return (
                        <tr key={i}>
                          <td>{i + 1}</td>
                          <td>
                            {OrderDetails?.pre_id}
                            {item?.order_id}
                          </td>
                          <td>
                            {item?.us_date}
                            {"  "}
                            {item?.us_time}
                          </td>
                          <td>{item?.remark_name}</td>
                          <td>
                            {selectedData.showData &&
                            selectedData.dataId === item.id ? (
                              <div
                                rel="40"
                                className="btn blue-btn"
                                onClick={() =>
                                  setSelectedData({
                                    showData: !selectedData.showData,
                                    dataId: item?.id,
                                  })
                                }
                              >
                                {" "}
                                Hide
                              </div>
                            ) : (
                              <div
                                rel="40"
                                onClick={() =>
                                  setSelectedData({
                                    showData: !selectedData.showData,
                                    dataId: item?.id,
                                  })
                                }
                                className="btn blue-btn"
                              >
                                {" "}
                                Show
                              </div>
                            )}
                            <br />
                            {selectedData.showData &&
                              selectedData.dataId === item.id && (
                                <div id="40">
                                  <textarea
                                    name="remark"
                                    className="form-control"
                                    defaultValue={item?.remark}
                                  ></textarea>
                                  <b>
                                    Whatsapp:
                                    {item?.whatsapp ? item?.whatsapp : "N"}
                                  </b>
                                  <b>
                                    Telephone:
                                    {item?.telephone ? item?.telephone : "N"}
                                  </b>
                                  <b>
                                    Refund:
                                    {item?.status_refund
                                      ? item?.status_refund
                                      : "N"}
                                  </b>
                                  <b>
                                    Voided:
                                    {item?.voided ? item?.voided : "N"}
                                  </b>
                                  <b>
                                    Chargeback:
                                    {item?.chargeback ? item?.chargeback : "N"}
                                  </b>
                                  <b>
                                    ReportError:
                                    {item?.report_error
                                      ? item?.report_error
                                      : "N"}
                                  </b>
                                </div>
                              )}
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan="5" align="center">
                        No record found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </StyledOrderBlock>
        </div>
      </div>

      <StyledBottomBlock>
        {OrderDetails?.order_change_status === "Awiating Response" ? (
          <>
            <Link
              to={`/admin/print-order/${OrderDetails?.id}`}
              className="btn blue-btn"
              target="_blank"
            >
              Print Info{" "}
              <span>
                <img src={rightArrow} alt="rightArrow" />
              </span>
            </Link>

            <button
              type="button"
              className="blue-btn"
              data-toggle="modal"
              data-target="#refundModal"
              onClick={() => setShow(true)}
            >
              View Email Content{" "}
              <span>
                <img src={rightArrow} alt="rightArrow" />
              </span>
            </button>
            <button
              type="button"
              className="blue-btn"
              data-toggle="modal"
              data-target="#defencePackModal"
              onClick={() => setShowDefence(true)}
            >
              Defence Pack 1{" "}
              <span>
                <img src={rightArrow} alt="rightArrow" />
              </span>
            </button>
            <button
              type="button"
              className="blue-btn"
              data-toggle="modal"
              data-target="#defencePackModal1"
              onClick={() => setShowDefencePack(true)}
            >
              Defence Pack 2{" "}
              <span>
                <img src={rightArrow} alt="rightArrow" />
              </span>
            </button>
            <button
              type="button"
              className="blue-btn"
              data-toggle="modal"
              data-target="#transactionModal"
              onClick={() => SetShowTransaction(true)}
            >
              Enter Transaction ID
            </button>
          </>
        ) : (OrderDetails?.doc_uploaded === 0 &&
            (OrderDetails?.order_change_status === "" ||
              OrderDetails?.order_change_status === null)) ||
          OrderDetails?.order_change_status === "Deleted Orders" ||
          OrderDetails?.order_change_status === "Refunds" ||
          OrderDetails?.order_change_status === "Complete Refunds" ||
          OrderDetails?.order_change_status === "Drop Box" ? (
          <Link
            to={`/admin/print-order/${OrderDetails?.id}`}
            className="btn blue-btn"
            target="_blank"
          >
            Print Info{" "}
            <span>
              <img src={rightArrow} alt="rightArrow" />
            </span>
          </Link>
        ) : OrderDetails?.doc_uploaded === 1 &&
          OrderDetails?.uploadDoc[0]?.is_downloaded === 0 &&
          (OrderDetails?.order_change_status === null ||
            OrderDetails?.order_change_status === "") ? (
          <>
            <Link
              to={`https://usa-passport.com/${OrderDetails?.d_sign}`}
              className="btn blue-btn"
              target="_blank"
            >
              Print Signature{" "}
              <span>
                <img src={rightArrow} alt="rightArrow" />
              </span>
            </Link>

            <button
              type="button"
              className="blue-btn"
              data-toggle="modal"
              data-target="#refundModal"
              onClick={() => setShow(true)}
            >
              View Email Content{" "}
              <span>
                <img src={rightArrow} alt="rightArrow" />
              </span>
            </button>
          </>
        ) : OrderDetails?.doc_uploaded === 1 &&
          OrderDetails?.uploadDoc[0]?.is_downloaded === 1 &&
          (OrderDetails?.order_change_status === null ||
            OrderDetails?.order_change_status === "") ? (
          <>
            <Link
              to={`/admin/print-order/${OrderDetails?.id}`}
              className="btn blue-btn"
              target="_blank"
            >
              Print Info{" "}
              <span>
                <img src={rightArrow} alt="rightArrow" />
              </span>
            </Link>
            <button
              type="button"
              className="blue-btn"
              data-toggle="modal"
              data-target="#defencePackModal"
              onClick={() => setShowDefence(true)}
            >
              Defence Pack 1{" "}
              <span>
                <img src={rightArrow} alt="rightArrow" />
              </span>
            </button>
            <button
              type="button"
              className="blue-btn"
              data-toggle="modal"
              data-target="#defencePackModal1"
              onClick={() => setShowDefencePack(true)}
            >
              Defence Pack 2{" "}
              <span>
                <img src={rightArrow} alt="rightArrow" />
              </span>
            </button>
            <button
              type="button"
              className="blue-btn"
              data-toggle="modal"
              data-target="#transactionModal"
              onClick={() => SetShowTransaction(true)}
            >
              Enter Transaction ID
            </button>
          </>
        ) : OrderDetails?.order_change_status === "Contact Customer Called" ? (
          <>
            <button
              type="button"
              className="blue-btn"
              data-toggle="modal"
              data-target="#refundModal"
              onClick={() => setShow(true)}
            >
              View Email Content{" "}
              <span>
                <img src={rightArrow} alt="rightArrow" />
              </span>
            </button>
            <button
              type="button"
              className="blue-btn"
              data-toggle="modal"
              data-target="#defencePackModal"
              onClick={() => setShowDefence(true)}
            >
              Defence Pack 1{" "}
              <span>
                <img src={rightArrow} alt="rightArrow" />
              </span>
            </button>
            <button
              type="button"
              className="blue-btn"
              data-toggle="modal"
              data-target="#defencePackModal1"
              onClick={() => setShowDefencePack(true)}
            >
              Defence Pack 2{" "}
              <span>
                <img src={rightArrow} alt="rightArrow" />
              </span>
            </button>
            <button
              type="button"
              className="blue-btn"
              data-toggle="modal"
              data-target="#transactionModal"
              onClick={() => SetShowTransaction(true)}
            >
              Enter Transaction ID
            </button>
          </>
        ) : OrderDetails?.order_change_status === "Contact Customer" ? (
          <>
            <Link
              to={`https://usa-passport.com/${OrderDetails?.d_sign}`}
              className="btn blue-btn"
              target="_blank"
            >
              Print Signature{" "}
              <span>
                <img src={rightArrow} alt="rightArrow" />
              </span>
            </Link>

            <button
              type="button"
              className="blue-btn"
              data-toggle="modal"
              data-target="#refundModal"
              onClick={() => setShow(true)}
            >
              View Email Content{" "}
              <span>
                <img src={rightArrow} alt="rightArrow" />
              </span>
            </button>
            <button
              type="button"
              className="blue-btn"
              data-toggle="modal"
              data-target="#defencePackModal"
              onClick={() => setShowDefence(true)}
            >
              Defence Pack 1{" "}
              <span>
                <img src={rightArrow} alt="rightArrow" />
              </span>
            </button>
            <button
              type="button"
              className="blue-btn"
              data-toggle="modal"
              data-target="#defencePackModal1"
              onClick={() => setShowDefencePack(true)}
            >
              Defence Pack 2{" "}
              <span>
                <img src={rightArrow} alt="rightArrow" />
              </span>
            </button>
            <button
              type="button"
              className="blue-btn"
              data-toggle="modal"
              data-target="#transactionModal"
              onClick={() => SetShowTransaction(true)}
            >
              Enter Transaction ID
            </button>
          </>
        ) : (
          " "
        )}

        <MyModal
          show={show}
          close={setShow}
          size={"lg"}
          modalHeadTitle={"Email Content"}
          showFooter={true}
          primaryButtonName={"Close"}
          secondaryButtonName={"Send Whatsapp Message"}
          OrderDetails={OrderDetails}
        >
        <EmailContent />
        </MyModal>
        <MyModal
          show={showDefence}
          close={setShowDefence}
          size={"lg"}
          modalHeadTitle={"Defence Pack Content"}
          showFooter={false}
          OrderDetails={OrderDetails}
        >
          <DefenceContent defencePackName={null} />
        </MyModal>
        <MyModal
          show={showDefencePack}
          close={setShowDefencePack}
          size={"lg"}
          modalHeadTitle={"Defence Pack Content"}
          showFooter={false}
          OrderDetails={OrderDetails}
        >
          <DefenceContent defencePackName={"defencePack1"} />
        </MyModal>
        <MyModal
          show={showTransaction}
          close={SetShowTransaction}
          size={"s"}
          modalHeadTitle={"Enter Defence Pack Details:"}
          showFooter={false}
          OrderDetails={OrderDetails}
        >
          <TransactionModal SetShowTransaction={SetShowTransaction} />
        </MyModal>
        <MyModal
          show={showDeleteRecord}
          close={handleClose}
          alertMsg={'Are you sure to remove the record for this customer permanently from th server'}
          size={"s"}
          confirmAlert={true}
          noEvent={() => handleClose()}
          yesEvent={() => handleRemoveAllRecord()}
      ></MyModal>
      </StyledBottomBlock>
    </>
  );
};

export default ViewOrders;
